import { Box, Flex, StackProps, Text } from "@chakra-ui/react";

function VerifiedSupplier(rootProps: StackProps) {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      background={"primary.600"}
      color="white"
      p="6px 8px"
      w="89px"
      borderRadius="4px 0 0 4px"
      spaing={0}
      {...rootProps}
    >
      <Text lineHeight="12px" fontSize="xs">
        verified supplier
      </Text>
      <Box mr="2px">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6777 6.8237L14.8507 6.65255C14.9187 6.58516 14.9659 6.49969 14.9868 6.40626C15.0078 6.31284 15.0015 6.21537 14.9688 6.1254C14.936 6.03543 14.8782 5.95673 14.8022 5.89861C14.7261 5.84049 14.635 5.80539 14.5396 5.79746L10.5331 5.40041L8.45457 1.2774C8.41182 1.19389 8.34686 1.12379 8.26683 1.07485C8.18681 1.0259 8.09483 1 8.00104 1C7.90724 1 7.81526 1.0259 7.73524 1.07485C7.65522 1.12379 7.59025 1.19389 7.5475 1.2774L5.46586 5.40041L1.45934 5.79746C1.36396 5.80539 1.27285 5.84049 1.19679 5.89861C1.12074 5.95673 1.06292 6.03543 1.03019 6.1254C0.997464 6.21537 0.991191 6.31284 1.01212 6.40626C1.03305 6.49969 1.0803 6.58516 1.14828 6.65255L4.44556 9.92171L3.22868 14.364C3.20367 14.4582 3.2064 14.5576 3.23655 14.6503C3.26669 14.7431 3.32297 14.8251 3.39862 14.8865C3.47426 14.948 3.56605 14.9863 3.66293 14.9968C3.75982 15.0074 3.85768 14.9897 3.94475 14.9459L6.26965 13.7939"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.53516 11.2659C7.53516 12.2562 7.92843 13.206 8.62846 13.9063C9.32849 14.6065 10.2779 14.9999 11.2679 14.9999C12.2579 14.9999 13.2074 14.6065 13.9074 13.9063C14.6074 13.206 15.0007 12.2562 15.0007 11.2659C15.0007 10.2756 14.6074 9.3258 13.9074 8.62554C13.2074 7.92527 12.2579 7.53186 11.2679 7.53186C10.2779 7.53186 9.32849 7.92527 8.62846 8.62554C7.92843 9.3258 7.53516 10.2756 7.53516 11.2659Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9304 10.1805L11.1232 12.5909C11.0829 12.6443 11.0318 12.6885 10.9731 12.7204C10.9144 12.7524 10.8495 12.7714 10.7828 12.7762C10.7161 12.781 10.6492 12.7714 10.5865 12.7481C10.5239 12.7249 10.4669 12.6884 10.4195 12.6413L9.48633 11.7078"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    </Flex>
  );
}

export default VerifiedSupplier;
