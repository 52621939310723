import { HStack, StackProps, useColorModeValue } from "@chakra-ui/react";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import FAIcon from "./util/FAIcon";

interface Props {
  defaultValue?: number;
  max?: number;
  size?: "sm" | "md" | "lg" | "xl";
  rootProps?: StackProps;
}

export const Rating = (props: Props) => {
  const { defaultValue = 0, max = 5, size = "md", rootProps } = props;
  const color = useColorModeValue("gray.300", "gray.600");
  const activeColor = useColorModeValue("blue.500", "blue.200");
  return (
    <HStack spacing="0.5" {...rootProps}>
      {Array.from({ length: max })
        .map((_, index) => index + 1)
        .map((index) => {
          if (index <= defaultValue) {
            return (
              <FAIcon
                icon={faStarSolid}
                key={index}
                iconProps={{
                  fontSize: size,
                  color: activeColor,
                }}
              />
            );
          } else {
            return (
              <FAIcon
                icon={faStar}
                key={index}
                iconProps={{
                  fontSize: size,
                  color,
                }}
              />
            );
          }
        })}
    </HStack>
  );
};
