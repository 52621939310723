import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Product } from "@prisma/client";
import NextLink from "next/link";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { ToggleInWishlistThunkAction } from "../../data/redux/wishlistSlice";
import AddToCartButton from "../cart/AddToCartButton";
import { FavouriteButton } from "../FavouriteButton";
import { PriceTag } from "../PriceTag";
import { Rating } from "../Rating";
import VerifiedSupplier from "./VerifiedSupplier";

export type ProductCardProps = {
  product: Product;
  rootProps?: StackProps;
};

export const ProductCard = (props: ProductCardProps) => {
  const { product, rootProps } = props;
  const dispatch = useAppDispatch();
  const { name, imageUrl, price, salePrice, rating, reviewCount } = product;
  const isInWishlist = useAppSelector((s) =>
    s.wishlist.items.includes(product.id)
  );
  const spacing = useBreakpointValue({ base: "4", md: "5" });
  const reviewColor = useColorModeValue("gray.600", "gray.400");

  const toggleWishlist = () => {
    dispatch(ToggleInWishlistThunkAction(product.id));
  };

  const sdgList = product.sdg
    ? (JSON.parse(product.sdg) as string[])
    : ([] as string[]);

  return (
    <LinkBox
      boxShadow="full-sm"
      borderRadius={useBreakpointValue({ base: "md", md: "xl" })}
      overflow="hidden"
      as={VStack}
      spacing={spacing}
      {...rootProps}
      alignItems="stretch"
    >
      <NextLink href={`/product/${product.id}`} passHref>
        <LinkOverlay
          as={VStack}
          spacing={spacing}
          alignItems="stretch"
          cursor="pointer"
          flex={1}
        >
          <Box position="relative" overflow="hidden">
            <AspectRatio ratio={4 / 3}>
              <Image
                src={imageUrl}
                alt={name}
                draggable="false"
                fallback={<Skeleton />}
              />
            </AspectRatio>
            {product.verified && (
              <VerifiedSupplier
                position="absolute"
                right="-12px"
                bottom="-5px"
                transform="scale(0.75)"
              />
            )}
            <FavouriteButton
              position="absolute"
              top="2"
              right="2"
              aria-label={`Add ${name} to your favourites`}
              onClick={toggleWishlist}
              isSelected={isInWishlist}
            />
          </Box>
          <Stack px={2} spacing={4}>
            <Stack spacing="1">
              <Text
                fontWeight="medium"
                color={useColorModeValue("gray.700", "gray.400")}
                isTruncated
                fontSize="sm"
                textAlign="center"
              >
                {name}
              </Text>
              <PriceTag
                rootProps={{
                  alignSelf: "center",
                }}
                price={price}
                salePrice={salePrice}
              />
            </Stack>
            {sdgList.length > 0 && (
              <Grid
                gridTemplateColumns={`repeat(${sdgList.length}, 1fr)`}
                justifyItems="center"
              >
                {sdgList.map((p) => (
                  <GridItem key={p}>
                    <Image src={p} boxSize="26px" alt={p} />
                  </GridItem>
                ))}
              </Grid>
            )}
            <HStack flexDirection={["column", "row"]}>
              {rating >= 0 && <Rating defaultValue={rating} size="sm" />}
              {reviewCount >= 0 && (
                <Text fontSize="xs" color={reviewColor}>
                  {reviewCount} Reviews
                </Text>
              )}
            </HStack>
          </Stack>
        </LinkOverlay>
      </NextLink>
      <AddToCartButton borderRadius="none" data={product} />
    </LinkBox>
  );
};

ProductCard.Messages = [...AddToCartButton.Messages];
