import { Button, ButtonProps } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { addShoppingCartItem } from "../../data/redux/shoppingCartSlice";
import type { ShoppingCartItem } from "../../types";

export type AddToCartButtonProps = {
  data: ShoppingCartItem["item"];
} & Omit<ButtonProps, "children" | "onClick">;

function AddToCartButton({ data, ...rootProps }: AddToCartButtonProps) {
  const dispatch = useAppDispatch();
  const t = useTranslations("components.AddToCartButton");
  const isInCart = useAppSelector(
    (s) => s.shoppingCart.items.findIndex((i) => i.item.id === data.id) > -1
  );

  const addToCart = () => {
    dispatch(addShoppingCartItem(data));
  };

  // Update label to "Im Warenkorb"
  return (
    <Button
      onClick={addToCart}
      colorScheme="primary"
      isFullWidth
      {...rootProps}
    >
      {isInCart ? t("label_in_cart") : t("label")}
    </Button>
  );
}

AddToCartButton.Messages = ["components"];

export default AddToCartButton;
