import { createIcon, IconProps } from "@chakra-ui/icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export type FAIconProps = {
  icon: IconDefinition;
  iconProps: IconProps;
};

const iCache: any = {};

function FAIcon({ icon, iconProps }: FAIconProps) {
  const [width, height, , , d] = icon.icon;
  const displayName = `${icon.prefix}-${icon.iconName}`;
  let I;
  if (displayName in iCache) {
    I = iCache[displayName];
  } else {
    I = createIcon({
      displayName: displayName,
      d: d as string,
      viewBox: `0 0 ${width} ${height}`,
    });
  }
  return <I {...iconProps} />;
}

export default FAIcon;
