import { IconButton, IconButtonProps, LightMode } from "@chakra-ui/react";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";

export type FavouriteButtonProps = IconButtonProps & {
  isSelected?: boolean;
};

export const FavouriteButton = ({
  isSelected,
  ...props
}: FavouriteButtonProps) => (
  <LightMode>
    <IconButton
      isRound
      bg="white"
      color={isSelected ? "red.500" : "gray.900"}
      size="sm"
      _hover={{ transform: "scale(1.1)" }}
      sx={{ ":hover > svg": { transform: "scale(1.1)" } }}
      transition="all 0.15s ease"
      icon={<FAIcon icon={isSelected ? faHeartSolid : faHeart} />}
      boxShadow="base"
      {...props}
    />
  </LightMode>
);
